<template>
  <div class="actus">
    <h1>Actualités</h1>
    <ul v-if="!loader">
      <li v-for="article in articles" :key="article.id">
        <ArticleFront v-bind:article="article" />
      </li>
    </ul>
    <Loader v-else />
  </div>
</template>

<script>
export default {
  components: {
    ArticleFront: () => import("../components/ArticleFront.vue"),
    Loader: () => import("../components/Loader.vue"),
  },
  name: "Actus",
  data() {
    return {
      articles: "null",
      loader: true,
    };
  },
  beforeCreate() {
    this.$http
      .get("https://api.opci-ethnodoc.fr/wp-json/wp/v2/posts?categories=2")
      .then(
        (response) => ((this.articles = response.data), (this.loader = false))
      );
  },
};
</script>

<style >
.actus ul {
  list-style-type: none;
}
</style>